@font-face {
  font-family: "iconfont"; /* Project id 2503788 */
  src: url('iconfont.woff2?t=1635131789846') format('woff2'),
       url('iconfont.woff?t=1635131789846') format('woff'),
       url('iconfont.ttf?t=1635131789846') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.iconjiekousheji:before {
  content: "\e615";
}

.iconshangpin:before {
  content: "\e624";
}

.iconkehu:before {
  content: "\e622";
}

.iconzu:before {
  content: "\e628";
}

.iconchakanyanjingshishifenxi2:before {
  content: "\e7d2";
}

.iconxiazai:before {
  content: "\e6ad";
}

.iconziyuanxhdpi:before {
  content: "\e62e";
}

.icontingyong:before {
  content: "\e693";
}

.iconqiyong:before {
  content: "\e64b";
}

.iconwenjian:before {
  content: "\e703";
}

.iconback:before {
  content: "\e670";
}

.iconshuju:before {
  content: "\e613";
}

.iconkexuejishu-:before {
  content: "\e9bf";
}

.iconjiankongguanli--:before {
  content: "\e636";
}

.iconxiaoshou:before {
  content: "\e612";
}

.iconfukuanguanli:before {
  content: "\e65a";
}

.iconshoukuan:before {
  content: "\e66d";
}

.iconcangkukucun:before {
  content: "\e633";
}

.iconcaigou:before {
  content: "\e67e";
}

.iconhtmal5icon19:before {
  content: "\e637";
}

.iconxitongguanli:before {
  content: "\e638";
}

.iconB:before {
  content: "\e704";
}

.iconreport-fill:before {
  content: "\e631";
}

.icontianjia:before {
  content: "\e634";
}

.icongengduo:before {
  content: "\e677";
}

.iconicon_guanbi:before {
  content: "\e6c7";
}

.iconxiangshang:before {
  content: "\e600";
}

.iconxiangxia:before {
  content: "\e601";
}

.iconbitian:before {
  content: "\e635";
}

.iconicon_shanchu:before {
  content: "\e614";
}

.iconbianjimian:before {
  content: "\e667";
}

.iconjichu:before {
  content: "\e60e";
}

.icontuichu:before {
  content: "\e682";
}

.iconcircle:before {
  content: "\e64f";
}

.iconshouye:before {
  content: "\e61e";
}

.iconshezhi:before {
  content: "\e654";
}

.iconicon_paging_left:before {
  content: "\e652";
}

.iconicon_paging_right:before {
  content: "\e653";
}

